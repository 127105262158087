.forgotMainWrapper {
  background: #f8f9fa;
  height: 100vh;
}

.forgotMainWrapper img {
  height: 250px;
  width: 220px;
}

.forgotMiddleWrapper h5 {
  margin-bottom: 20px;
}

.forgotMiddleWrapper input {
  margin-top: 33px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #181818;
}

.forgotMainWrapper button {
  /* height: 47px; */
  width: 100%;
  background: #dd3944;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 12px 0;
  margin-top: 33px;
}

.forgotMiddleWrapper input {
  margin-top: 33px;
}

.forgotMainWrapper img {
  height: 250px;
  width: 219px;
  /* margin-top: 25px; */
  margin-bottom: 25px;
}

/* PasswordScreen */

.passwordButton {
  width: 100%;
  background: #dd3944;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 12px 0;
  margin-top: 33px;
}

.forgotMainWrapper label {
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
}

.error-message {
  font-size: 0.7rem;
  font-weight: 500;
  color: red;
  margin-top: 0.2rem;
}

.maincontent {
  padding: 0 25px;
}

/* Login Page CSS */
.loginHighlight {
  background: #fff;
}

.loginContainer {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.bannerImage {
  height: 100vh;
  width: 100%;
  background: url("../images/YAAMS-login_img.jpg");
  background-size: cover;
  background-position: center center;
}

.logo {
  margin-top: 35px;
  margin-left: 50px;
}

.loginWrapper {
  width: 90%;
  margin: auto;
  text-align: center;
  margin-top: 100px !important;
}

.loginLeftContainer {
  padding: 0px 4%;
}

.userIcon {
  left: 20px;
  font-size: 22px;
  color: #dd3944;
  margin-top: 15px;
}

.eyeIcon {
  position: absolute;
  right: 22px;
  top: 17px;
}

.loginNewSearch {
  padding: 10px 10px 10px 50px;
  font-size: 15px;
  border: none;
  resize: none;
  outline: none;
  border-bottom: 1px solid #c4c4c4 !important;
  width: 100%;
  border-radius: 50px;
  background-color: #f8f9fa;
}

.loginButton {
  padding: 10px 50px;
  border-radius: 50px;
  background-color: #dd3944;
  color: #fff;
  font-size: 15px;

  letter-spacing: 1px;
}

@media (max-width: 767px) {
  .loginContainer {
    flex-direction: column-reverse;
  }

  .loginLeftContainer {
    padding: 1% 4% 12% 4%;
  }

  .userIcon {
    /* left: 60px; */
    font-size: 22px;
    color: #dd3944;
    margin-top: 15px;
  }

  .loginWrapper {
    margin-top: 30px !important;
  }
}

.versionYAAMS {
  text-align: right;
}

@media (max-width: 767px) {
  .loginContainer {
    flex-direction: column-reverse;
  }

  .userIcon {
    font-size: 22px;
    color: #dd3944;
    margin-top: 15px;
  }

  .loginWrapper {
    margin-top: 30px !important;
  }

  .loginButton {
    margin-bottom: 10px;
  }
}

.versionYAAMS {
  text-align: right;
  /* margin-top: -100px; */
}

/*Login Page CSS ENds */
