@font-face {
  font-family: "Gill Sans MT";
  src: local("GillSansMt"),
    url("../src/fonts/Gill Sans/GIL_____.TTF") format("truetype");
  /* font-weight: bold; */
}

@font-face {
  font-family: "Gill Sans MT";
  font-style: normal;
  font-weight: normal;
  src: local("GillSansMt"),
    url("../src/fonts/Gill Sans/gill-sans-mt-bold.ttf") format("truetype");
}

/* input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {  

   opacity: 1;

} */
body,
h1,
h2,
h3,
h4,
h5,
input,
textarea {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  /* font-weight: bold; */
}

body {
  background: #f5f7ff !important;
}

table {
  width: 100%;
  font-size: 15px;
}

tr:nth-child(odd) {
  background: #f1f1f1;
}

th,
td {
  text-align: left !important;
  padding: 10px 5px;
}

label {
  margin-bottom: 4px;
}

.navbar {
  padding: 15px 10px;
  background: #fff;
  border: none;
  border-radius: 0;
  margin-bottom: 30px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
}

.hamburger {
  background: transparent;
  border: none;
  color: #dd3944;
}

.navbar-btn {
  box-shadow: none;
  outline: none !important;
  border: none;
}

.maincontent {
  padding: 0 25px;
}

.highlight {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px #ddd;
  margin: 15px 0px;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* ---------------------------------------------------
  SIDEBAR STYLE
----------------------------------------------------- */

.wrapper {
  display: flex;
  width: 100%;
  align-items: stretch;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  background: #fff;
  color: #262626;
  transition: all 0.3s;
  box-shadow: 0px 0px 10px #eee;
}

#sidebar.active {
  margin-left: -250px;
}

#sidebar .sidebar-header {
  margin-top: 0px;
  text-align: center;
}

#sidebar ul.components {
  padding: 20px 0;
}

#sidebar ul p {
  color: #464646;
  padding: 8px 12px;
}

#sidebar ul li {
  padding: 3px 12px;
}

#sidebar ul li a {
  padding: 8px;
  font-size: 16px;
  display: block;
  position: relative;
  text-decoration: none;
  font-weight: 500;
  color: #484848;
  border-radius: 8px;
}

#sidebar ul li a:hover {
  color: #dd3944;
  background: #f5f7ff;
}

#sidebar ul li a svg {
  margin-right: 8px;
  width: 16px;
}

#sidebar ul li.active>a,
a[aria-expanded="true"] {
  color: #dd3944;
  background: #f5f7ff;
}

a[data-toggle="collapse"] {
  position: relative;
}

.dropdown-toggle_alt::after {
  display: block;
  position: absolute;
  top: 50%;
  right: 20px;
  transform: translateY(-50%);
}

ul.CTAs {
  padding: 20px;
}

ul.CTAs a {
  text-align: center;
  font-size: 0.9em !important;
  display: block;
  border-radius: 5px;
  margin-bottom: 5px;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

/* ---------------------------------------------------
  CONTENT STYLE
----------------------------------------------------- */

#content {
  width: 100%;
  transition: all 0.3s;
}

.dashbox {
  position: relative;
}

.dashbox .percent {
  position: absolute;
  right: 20px;
  bottom: 17px;
  margin-bottom: 0;
}

.dashbox h4 a {
  color: #fff !important;
}

.highlight h4 a {
  text-decoration: none;
  color: #212529;
  position: relative;
}

.highlight h4 a i {
  font-size: 20px;
}

.dashbox i {
  font-size: 40px;
  opacity: 0.8;
  margin-top: 25px;
}

.feed .message {
  margin-top: 17px;
  display: flex;
  align-items: center;
}

.feed .message h5 {
  background: #67cc94;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  border-radius: 50%;
  font-weight: 400;
  margin-bottom: 0;
  margin-right: 10px;
  float: left;
}

.feed .message p {
  margin-bottom: 0;
  font-size: 14px;
  /* width: calc(100% - 50px);
  float: right; */
}

.whoinbox p {
  margin-bottom: 7px;
}

.whoin {
  list-style: none;
  padding: 0;
  display: flex;
}

.whoin li {
  cursor: pointer;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 500;
}

.whoin.grey li {
  background: #c4c4c4;
}

.whoin.orange li {
  background: #ed6c30;
  color: #fff;
}

.whoin.blue li {
  background: #0086b3;
  color: #fff;
}

.whoin.red li {
  background: #dd3944;
  color: #fff;
}

.whoin.black li {
  background: #000;
  color: #fff;
}

.onevent {
  display: flex;
  justify-content: space-between;
}

.onevent a {
  text-decoration: none;
}

.header_profile a.dropdown-toggle_alt {
  display: inline-block;
  height: 36px;
  width: 36px;
  border-radius: 50%;
  overflow: hidden;
}

.header_profile .dropdown-toggle_alt::after {
  display: none;
}

.header_profile .dropdown-menu {
  box-shadow: 0px 1px 10px 0 rgb(0 0 0 / 15%);
  border-radius: 4px !important;
  right: 0;
  left: auto !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  top: 120% !important;
}

.header_profile .dropdown-item {
  color: #76838f;
  background-color: transparent !important;
}

.profile-pic {
  color: transparent;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.3s ease;
  height: 150px;
  width: 150px;
  margin: 0 auto;
}

.profile-pic .-label {
  cursor: pointer;
  height: 150px;
  width: 150px;
}

.profile-pic:hover .-label {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 10000;
  color: #fafafa;
  transition: background-color 0.2s ease-in-out;
  border-radius: 100px;
  margin-bottom: 0;
}

.profile-pic span {
  display: none;
  padding: 0.2em;
}

.profile-pic:hover span {
  display: inline-flex;
}

.profile-pic input {
  display: none;
}

.profile-pic img {
  position: absolute;
  object-fit: cover;
  width: 150px;
  height: 150px;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.35);
  border-radius: 100px;
  z-index: 0;
}

/* Attendance CSS */
.light-bg {
  background-color: #e4f2f5 !important;
}

.emp_data tr,
th,
td {
  color: #535353;
}

.row-bg {
  background-color: #f8f9fa;
}

.atten {
  font-weight: 600;
}

.atten.high {
  color: #67cc94;
}

.atten.low {
  color: #dd3944;
}

.table_filter {
  border-bottom: 1px solid #c4c4c4;
}

.cust_dropdown {
  margin-bottom: 2%;
}

.emp_count {
  margin-bottom: 2%;
}

.emp_countt {
  color: #181818;
  font-weight: 500;
}

.theme_red {
  color: #dd3944;
}

.dropdown-toggle_alt.loc {
  background-color: #fff;
  color: #181818;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 2%;
}

.dropdown-toggle_alt.team {
  background-color: #fff;
  color: #c4c4c4;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  margin-bottom: 2%;
}

.dropdown-toggle_alt.loc::after {
  display: inline-block;
  margin-left: 20px;
  color: #181818;
  position: relative;
  top: auto;
  right: auto;
  transform: inherit;
}

.dropdown-toggle_alt.team::after {
  display: inline-block;
  margin-left: 20px;
  color: #181818;
  position: relative;
  top: auto;
  right: auto;
  transform: inherit;
}

.dt_picker {
  background-color: #fff;
  color: #181818;
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-container button {
  background: #ddd;
  border: none;
  border-radius: 0px 5px 5px 0px;
  display: inline-block;
  margin-left: -5px;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.search-container input[type="text"] {
  background-color: #f8f9fa;
  color: #181818;
  border: none;
  border-radius: 5px 0px 0px 5px;
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.emp_info_popup {
  text-decoration: none;
  color: #535353;
}

.emp_info_popup:hover {
  color: #dd3944;
}

/* Attendance - Employee Details Popup */
@media (min-width: 576px) {
  .modal-dialog.cust_modal_width {
    max-width: 700px !important;
    margin: 1.75rem auto;
  }
}

.emp_name {
  font-size: 22px;
  font-weight: bold;
  color: #181818;
}

.modal-body.cust_padding {
  padding: 1rem 2.5rem;
}

.more_details {
  margin-top: 10%;
  color: #dd3944;
  text-decoration: none;
}

/* .empl.d-flex.flex-column{
width: 100%;
} */

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
  color: #000;
}

.nav-tabs .nav-link {
  border-top-left-radius: 0rem !important;
  border-top-right-radius: 0rem !important;
}

#myTab .nav-link.active {
  background-color: #e4f2f5;
  font-weight: 600;
  border-color: transparent;
  color: #dd3944 !important;
  border-bottom: 4px solid #dd3944 !important;
}

.nav-link {
  /* height: 77px; */
  font-size: 18px;
  background-color: #fff;
}

.nav-link:hover {
  border: none;
}

.nav-link:focus {
  outline: none;
}

.tab-pane {
  height: auto;
}

.form-control {
  /* height: "45px";  Edited By Ajay */
  height: auto !important;
  position: relative;
}

.form-control.req {
  background: #f9001d14;
}

.form-control:focus {
  outline: none;
  box-shadow: none;
  border: 1px solid #dd3944;
}

.flight-where-from:after {
  position: absolute;
  content: "23";
  width: 20px;
  height: 20px;
  border-radius: 50%;
}

.custom-button {
  height: 45px;
  background-color: #dd3944;
  border: none;
  color: #fff;
}

.custom-button:hover,
.custom-button:focus {
  background-color: #dd3944;
  box-shadow: none;
  outline: none;
}

.scroll-y {
  overflow-y: scroll;
  height: 266px;
}

.version_control_scroll-y {
  overflow-y: scroll;
  height: 400px;
}

.info_subtxt {
  font-size: 14px;
  color: #181818;
  font-weight: 400;
}

.contact_highlight {
  font-size: 16px;
}

.blazing_red {
  color: #dd3944;
}

.w-45 {
  width: 45%;
}

.w-25 {
  width: 25%;
}

.contact_info_head {
  font-size: 20px;
  font-weight: lighter;
}

.mngr_name {
  font-size: 12px;
}

.desig {
  color: #181818;
  font-weight: 300;
}

.teah_head {
  font-size: 12px;
}

.team_n {
  font-size: 15px;
  font-weight: 500;
}

.desig_alt {
  font-size: 12px;
}

.team_mem_name {
  font-size: 14px;
}

.cta_link {
  color: #dd3944;
  text-decoration: none;
}

.cta_link:hover {
  color: #dd3944;
}

/* Detailed Profile For Attendance */

.nav-item.cust_flex {
  flex: 0.2;
}

.txt {
  font-size: 14px;
}

.emp_personal {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 20px;
}

.info_head,
.education {
  font-size: 14px;
  font-weight: 600;
}

.edu_loc {
  font-size: 14px;
  font-weight: 400;
}

.maincontent {
  height: 100%;
}

.highlight {
  height: 100%;
}

.data_table {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 15px;
}

.input-group.c_width {
  width: 30%;
}

.dt_picker {
  height: 45px;
}

div#faq_tab_2 {
  border: 1px solid #c4c4c4;
  border-radius: 15px;
  padding: 30px;
}

.atten_percentage {
  border: 1px solid #c4c4c4;
  border-radius: 10px;
  padding: 15px;
}

.status.present_count {
  color: #0086b3;
}

.status.late_count {
  color: #f6ae3f;
}

.status.absent_count {
  color: #dd3944;
}

.status.work_from_home {
  color: #3a573c;
}

.status.leave_count {
  color: #d252a5;
}

.tab-pane.fade.as {
  height: 100%;
}

.p_dot.ontime {
  background-color: #0086b3 !important;
}

.p_dot.lt {
  background-color: #f6ae3f !important;
}

.filtrs {
  border-bottom: 1px solid #c4c4c4;
}

.det_link {
  color: #dd3944;
  text-decoration: none;
}

.det_link:hover {
  text-decoration: underline;
  color: #dd3944;
}

.input-group.n_width {
  width: 15%;
}

.modal-dialog.custalt_modal_width {
  max-width: 1000px;
}

.emp_att_tbl {
  height: 360px;
  overflow-y: scroll;
}

.date_hd {
  background-color: #fff;
  padding: 5px;
  font-size: 11px;
  top: -12px;
  left: 10px;
  color: #c4c4c4;
}

.file_lnk {
  text-decoration: none;
  color: #000;
}

.file_lnk:hover {
  color: #dd3944;
  cursor: pointer;
}

.file_table tr th {
  background-color: #fff;
  color: #94a0a9;
  font-size: 14px;
  font-weight: 500;
}

.file_table tr:nth-child(odd) {
  background-color: #fff;
}

.all_drop {
  display: flex;
}

.all_drop .dropdown {
  margin-right: 15px;
}

/* ---------------------------------------------------
  MEDIAQUERIES
----------------------------------------------------- */

@media (max-width: 768px) {
  #sidebar {
    margin-left: -250px;
  }

  #sidebar.active {
    margin-left: 0;
  }

  #sidebarCollapse span {
    display: none;
  }
}

/* ----------------------
propfile image in popup 
----------------------- */

.profile_img {
  width: 50px;
  border-radius: 100%;
}

/*----------------------------------
 ------dashboard Edited Css---------
 -----------------------------------  */
.highlight {
  height: auto;
}

/* 
-------------------------------------------
-------Deshboard Feed Scrolling starts------
-------------------------------------------- */

.overflow-feed {
  overflow-y: scroll;
  height: 250px;
}

.overflow-events {
  overflow-y: scroll;
  height: 110px;
}

/* 
-------------------------------------------
-------Deshboard Feed Scrolling ENds------
-------------------------------------------- */

.table_pagination {
  width: 100%;
}

.p_dot {
  height: 11px;
  width: 11px;
  background-color: #dd3944;
  margin-right: 4px;
  border-radius: 50%;
  display: inline-block;
}

/* -------------CSS for React Bootstrap Table 2 starts ------------  */
.react-bootstrap-table table {
  table-layout: auto !important;
  overflow: auto;
}

.react-bootstrap-table {
  overflow-y: auto !important;
  max-height: 350px;
  overflow-x: hidden;
}

td.selection-cell,
th.selection-cell-header {
  padding-top: 15px;
  padding-left: 10px;
}

@media (max-width: 768px) {
  .react-bootstrap-table {
    overflow-y: auto !important;
    max-height: 350px;
    overflow-x: auto;
  }
}

/*------------- CSS for React Bootstrap Table 2 ends   ------------  */

/* ---------------Login eye ------------------------ */
.eyeicon {
  position: relative;
}

.eyeicon svg {
  position: absolute;
  right: 10px;
  top: 11px;
  cursor: pointer;
}

/* ------------Login eye End ------------------------ */

/* ---------------Change Password eye Starts------------------------ */

.eye_icon svg {
  position: absolute;
  right: 10px;
  bottom: 10px;
  cursor: pointer;
}

/* ---------------Change Password eye Ends------------------------ */

/* Change Password Screen Ends */

/* Settings - Leave */
.tab_img,
.tab_img_active {
  width: 68px;
  margin: auto;
  /* filter: brightness(5); */
}

.w-100 {
  width: 100%;
}

.tab_img {
  display: block;
}

.tab_img_active {
  display: none;
}

.nav-link.active .tab_img {
  display: none;
}

.nav-link.active .tab_img_active {
  display: block;
}

.cust_leave_popup {
  padding: 10px 10px;
  border: 1px solid #d01d2b;
  color: #d01d2b;
  text-decoration: none;
  border-radius: 5px;
}

.cust_leave_popup:hover {
  background: #d01d2b;
  color: #fff;
}

.save_btn {
  color: #fff;
  text-decoration: none;
  background-color: #d01d2b;
  padding: 8px 30px;
  border-radius: 5px;
  border: none;
  font-size: 20px;
}

.save_btn:hover {
  color: #fff;
}

@media (min-width: 576px) {
  .modal-dialog.modal_cust_width {
    max-width: 820px !important;
    margin: 1.75rem auto;
  }
}

.leave_thead {
  background: #e4f2f5 !important;
}

tr:nth-child(odd) {
  background: #f8f9fa;
}

.fa-trash-can:hover {
  cursor: pointer;
  color: #d01d2b;
}

/* End of Settings - Leave */

/* Button Disable in setting */

.dis {
  color: #fff;
  text-decoration: none;
  background-color: #e9babe;
  padding: 10px 25px;
  border-radius: 5px;
}

/* Added  By Siddharth for API response */

.msg_success {
  color: green;
  font-size: 14px;
  font-weight: 600;
}

.msg_fail {
  color: #dd3944;
  font-size: 14px;
  font-weight: 600;
}

/* User Help added CSS */

.resolveInput {
  margin-top: 10px;
  padding: 15px;
  width: 100%;
}

.resolvedBtn {
  margin-top: 10px;
}

/* USer help added CSS ends */

/* FOrgot Password Css */

.forgotMainWrapper {
  background: #f8f9fa;
  height: 100vh;
}

.forgotMainWrapper img {
  height: 250px;
  width: 220px;
}

.forgotMiddleWrapper h5 {
  margin-bottom: 20px;
}

.forgotMiddleWrapper input {
  margin-top: 33px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #181818;
}

.forgotMainWrapper button {
  /* height: 47px; */
  width: 100%;
  background: #dd3944;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 12px 0;
  margin-top: 33px;
}

.forgotMiddleWrapper input {
  margin-top: 33px;
}

.forgotMainWrapper img {
  height: 250px;
  width: 219px;
  /* margin-top: 25px; */
  margin-bottom: 25px;
}

/* PasswordScreen */

.passwordButton {
  width: 100%;
  background: #dd3944;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 12px 0;
  margin-top: 33px;
}

.forgotMainWrapper label {
  display: block;
  text-align: left;
  font-weight: 600;
  font-size: 16px;
}

.error-message {
  font-size: 0.7rem;
  font-weight: 500;
  color: red;
  margin-top: 0.2rem;
}

.error-message-ui {
  font-size: 20px;
  font-weight: 500;
  color: red;
  margin-top: 0.2rem;
}

/* Forgot password Css ends */

/* .printable {
  display: none;
  
} */

@media print {
  /* .img-thumbnail{
    width: 50%; max-width: 764px;
  } */

  .non-printable {
    display: none;
  }

  /* .printable {
    display: block;
  } */
}

/* GeneralSetting Styles */

.newSearchContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.newsearch_box svg {
  position: absolute;
  right: 11px;
  top: 12px;
  font-size: 20px;
}

.newSearchWrapper {
  padding: 7px;

  resize: none;
  outline: none;
}

.newSearch {
  padding: 10px;
  font-size: 20px;
  border: none;
  resize: none;
  outline: none;
  border-bottom: 1px solid #c4c4c4 !important;
}

.newSearchContainer svg {
  margin-left: -18px;
}

/* GeneralSetting Styles ends  */

/* Updated CSS */

.newSearchContainer input:focus {
  border: none;
}

.newSearchContainer .search-label {
  display: block !important;
  width: 100%;
}

/* Employee List Dropown starts*/
.employeeDropdown {
  display: block;
  box-shadow: 0px 0px 7px #7e7e7e33;
  margin: auto;
  left: 0 !important;
  right: 0 !important;
}

.employeeListDropdownBtn {
  background-color: #808080;
}

button#dropdown-menu-align-end {
  background-color: #808080;
}

/* Employee List Dropown Ends*/

/*Time input Starts*/

input[type="time"] {
  border: none;
  color: #2a2c2d;
  font-size: 14px;
  /* font-family: helvetica; */
  /* width: 180px; */
}

/* Wrapper around the hour, minute, second, and am/pm fields as well as
the up and down buttons and the 'X' button */
input[type="time"]::-webkit-datetime-edit-fields-wrapper {
  display: flex;
}

/* The space between the fields - between hour and minute, the minute and
second, second and am/pm */
input[type="time"]::-webkit-datetime-edit-text {
  padding: 19px 5px;
}

/* The naming convention for the hour, minute, second, and am/pm field is
`-webkit-datetime-edit-{field}-field` */

/* Hour */
input[type="time"]::-webkit-datetime-edit-hour-field {
  background-color: #e2dcc8;
  border-radius: 15%;
  padding: 19px 13px;

  font-weight: bold;
}

/* Minute */
input[type="time"]::-webkit-datetime-edit-minute-field {
  background-color: #e2dcc8;
  border-radius: 15%;
  padding: 19px 13px;
  font-size: 20px;
  font-weight: bold;
}

/* AM/PM */
input[type="time"]::-webkit-datetime-edit-ampm-field {
  background-color: #7155d3;
  border-radius: 15%;
  color: #fff;
  padding: 19px 13px;
}

/* 'X' button for resetting/clearing time */
input[type="time"]::-webkit-clear-button {
  display: none;
}

/* Up/Down arrows for incrementing/decrementing the value */
input[type="time"]::-webkit-inner-spin-button {
  display: none;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  font-size: 30px;
  cursor: pointer;
  /* margin-right: 200px; */
}

/*Time input Ends*/

.loadingGif {
  text-align: center;
}

/* Change Password Screen */
.changeMainWrapper {
  background: #fafafa;
  height: 75vh;
}

.changeMainWrapper img {
  width: 80%;
}

.changeMiddleWrapper h5 {
  margin-bottom: 20px;
  font-weight: bold;
}

.changeMiddleWrapper input {
  margin-top: 33px;
  padding: 12px;
  border-radius: 5px;
  border: 1px solid #181818;
}

.changeMainWrapper button {
  /* height: 47px; */
  width: 100%;
  background: #dd3944;
  color: white;
  margin-top: 20px;
  border-radius: 5px;
  padding: 12px 0;
  margin-top: 33px;
}

.changeMiddleWrapper input {
  margin-top: 33px;
}

/* Change Password Screen Ends */

/* DEpartment Btn */
.departmentBtn {
  /* position: absolute; */

  background-color: #515dd3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 15px;
  min-height: 30px;
  min-width: 120px;
}

.departmentBtn:hover {
  background-color: #ff1818;
  transition: 0.7s;
}

.departmentBtn:disabled,
.departmentBtn[disabled] {
  border: 1px solid black;
  background-color: grey;
  color: white;
  padding: 15px;
  cursor: not-allowed;
}

.departmentBtn:active {
  background-color: #ffbf00;
}

.departmentBtnSecondary {
  background-color: #515dd3;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 10px;
  min-height: 20px;
  min-width: 100px;
}

.departmentBtnSecondary:active {
  background-color: #ffbf00;
}

.departmentSettingBtn {
  background-color: #f8f9fa;
  border-radius: 10px;
  padding: 8px;
  width: 200px;
  justify-content: space-between;
  text-align: left;
  border: none;
}

/* For Icon */

.formSelectPlus {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("https://www.svgrepo.com/show/292065/plus.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 16px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

/* Department Btn ends */

/* FetchBitrthday  */

.modalBtn {
  background-color: #0a0a23;
  color: #fff;
  border: none;
  border-radius: 10px;
  padding: 8px;
  width: 150px;
  justify-content: center;
  align-items: center;
}

.modalBtn:hover {
  background-color: red;
  transition: 0.7s;
}

/* Fetch birthday ends */

.form-download {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("https://www.svgrepo.com/show/14689/download.svg");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 20px 16px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  appearance: none;
}

/* Login version modal Starts */

.modal-40w {
  overflow-y: initial !important;
  height: 650px;
}

/* Login version modal Ends */

/* Update USer Tabs */

.uploadBtn {
  background-color: #dd3944;
  border: none;
  color: #fff;
  border-radius: 5px;
  padding: 6px;
}

.updateUserTabs {
  border-bottom: 2px solid red;
  justify-content: center;
}

.updateUserTabs li {
  margin-bottom: -1px;
}

.updateUserTabs li .nav-link {
  border: 2px solid;
  border-color: transparent !important;
  border-top-right-radius: 5px !important;
  border-top-left-radius: 5px !important;
  color: #141414;
  font-size: 16px;
}

.updateUserTabs li .nav-link.active {
  border: 2px solid red !important;
  border-bottom-color: #f5f7ff !important;
  background: transparent !important;
  color: red;
}

/* Update User Tabs Ends */

/* Multi select in attendance starts */

.dropdown-heading-value span {
  display: block;
  width: 200px;
}

/* Multi select in attendance ends */

/* Team Table Scroll Starts */

.teamTableScroll {
  max-height: 44vh !important;
  overflow-y: scroll;
}

/* Team Table Scroll Ends */

/* Email Leave Page */
.leave_request {
  width: 400px;
  margin: auto;
  text-align: center;
}

.leave_request form {
  background: #fff;
  border-radius: 5px;
  padding: 30px 30px;
  border: 1px solid #ebebeb;
}

.map_hyperlink_button {
  color: #000;
  background-color: transparent;
  border-color: transparent;
}

/* Modal QR */

/* .qr_box {
  width: 100%;
  height: 400px;
} */

.qr_box img {
  object-fit: cover;
}

/* MOdal Qr */

/* 
.msg_fail a:hover {
  text-decoration: none;
  color: #dd3944;
} */

/* Recruitment Dashboard Starts */

.recruit_highlight div {
  color: #c4c4c4;
}

.recruitparent a {
  color: #000;
  text-decoration: "none";
}

.recruit_highlight:hover h1,
.recruit_highlight:hover div {
  color: #dd3944;
}

.recruit_highlight {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 0px 12px #ddd;
  margin: 15px 0px;
}

/*  Recruitment Dashboard Ends*/

/* Vacancy Page */

ul.vac_tabs {
  list-style: none;
  display: flex;
  border-bottom: 2px solid red;
  padding: 0;
}

ul.vac_tabs li a.active {
  border: 2px solid #dd3974;
  border-bottom-color: #fff;
  color: #dd3974;
}

ul.vac_tabs li a {
  padding: 10px 20px;
  display: inline-block;
  text-decoration: none;
  margin-bottom: -2px;
  border-radius: 3px 3px 0px 0px;
}

.vacancyTabs .nav-link {
  width: 200px;
  border: none !important;
  background-color: #fff;
  color: #000;
}

.vacancyTabs .nav-link.active {
  background-color: #dd3974;
  color: #fff;
}

/* .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
  color: #fff;
  background-color: #dd3974;
} */
/* Vacancy Page Ends */

/* Create Vacancy Page */

.date {
  position: absolute;
  top: 100px;
  left: 800px;
  z-index: 2;
}

.cancelBtnPosition {
  position: absolute;
  bottom: 15px;
  left: 142px;
}

.cancelBtnPositionInterview {
  position: absolute;
  top: 309px;
  left: 142px;
}

.appraisalCancelBtn {
  position: absolute;
  bottom: 32px;
  left: 133px;
}


.personalDetailCancelBtn {
  position: absolute;
  bottom: 20px;
  left: 130px;
}

.rightSideDrawer {
  transition-timing-function: linear !important;
  transition-duration: 500ms !important;
  width: 200px;
}

/* Create Vacancy Page Ends */

/* CandidateAcceptanceForm */

.candidateHighlight {
  background: #fff;
  padding: 20px;
  /* border-radius: 8px; */
  box-shadow: 0px 0px 12px #ddd;
  margin: 15px 0px;
 
}
.candidateOfferAccepttanceHighlight {
  background: #fff;
  padding: 20px;
  /* border-radius: 8px; */
  box-shadow: 0px 0px 12px #ddd;
  margin: 15px 0px;
 
}

.candidateMainContent {
  padding: 0 25px;
}

/* CandidateAcceptanceForm Ends */


.circle_profile_img{
  width: 200px;
  height: 200px;
  border-radius: 50%;
  overflow: hidden;

}
.candidateMainContent label {
  width: 120px;
}