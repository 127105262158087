/* New CSS 16-01-23 */
#myTabLeave .nav-link.active {
  background-color: #db3f49 !important;
  color: #fff !important;
  border-bottom: none !important;
  border: none;
  filter: drop-shadow(2px 2px 15px rgba(0, 0, 0, 0.12));
}

#myTabLeave .nav-link {
  background-color: #FFEBEC !important;
  color: #000 !important;
  font-weight: 600;
  border: 1px solid #c4c4c4;
  padding: 30px;
  border-radius: 5px !important;
}

#myTabLeave .nav-link.gen_tab {
  /* background: url(../../../images/new_images/general_bg.svg); */
  background: url(../../../images/new_images/general_bg.svg);
  position: relative;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30%;
}

#myTabLeave .nav-link.org_tab {
  background: url(../../../images/new_images/org_bg.svg);
  position: relative;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30%;
}

#myTabLeave .nav-link.leave_tab {
  background: url(../../../images/new_images/leave_bg.svg);
  position: relative;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30%;
}

#myTabLeave .nav-link.att_tab {
  background: url(../../../images/new_images/attendance_bg.svg);
  position: relative;
  top: 0;
  right: 0;
  background-repeat: no-repeat;
  background-position: top right;
  background-size: 30%;
}

.tab_name {
  font-size: 14px;
}

#myTabLeave {
  border: none;
}

button.inner.active {
  filter: drop-shadow(0px 0px 0px #fff) !important;
  width: 100%;
  padding: 10px;
  border-radius: 0px;
  text-align: left;
}

button.inner {
  padding: 10px !important;
  text-align: left;
  border-radius: 0px !important;
  width: 100%;
  border: none !important;
  background-color: #fff !important;
}

.br {
  border-right: 1px solid #c4c4c4;
}

.bt {
  border-top: 1px solid #c4c4c4;
}

.bb {
  border-bottom: 1px solid #c4c4c4;
}

.pt {
  padding-top: calc(var(--bs-gutter-x) * .5);
}

.highlight {
  height: 100%;
}

.tab-pane {
  height: 100%;
}

.sec-btn {
  background-color: #F8F9FA;
  border-radius: 5px;
  max-width: 100px;
  min-width: 80px;
  color: #000;
}

.ter-btn {
  background-color: #DD3944;
  border-radius: 5px;
  max-width: 100px;
  min-width: 80px;
  color: #fff;
}

.maincontent {
  min-height: 95%;
}

.w-100 {
  width: 100%;
}

.inp_field {
  width: 80%;
}

.warn_sub {
  font-weight: 400;
  font-size: 12px;
  line-height: 12px;
  letter-spacing: 0.5px;
  color: #DD3944;
}

.roles_heading {
  font-size: 22px;
  font-weight: 500;
}

.role_tab {
  border-bottom: 1px solid #c4c4c4;
}

.nav-link.roles_inner_tab.active {
  background-color: #fff !important;
  color: #db3f49 !important;
  filter: drop-shadow(0px 0px 0px #fff) !important;
  border-bottom: 2px solid #db3f49 !important;
  border-radius: 0px;
}

.nav-link.roles_inner_tab {
  padding: 10px 20px;
  background-color: #fff !important;
  border: none;
  color: #c4c4c4 !important;
  font-weight: 400;
}

.row_head {
  font-weight: 600;
  font-size: 14px;
  line-height: 13px;
  letter-spacing: 0.5px;
}

.form-check-input:checked {
  background-color: #db3f49;
  border-color: #db3f49;
}

.acc-btn {
  color: #fff;
  background-color: #db3f49;
  border-color: #db3f49;
}

.acc-btn:hover {
  color: #db3f49;
  background-color: #FFF;
  border-color: #db3f49;
}

.acc-btn:focus {
  color: #fff;
  background-color: #db3f49;
  border-color: #db3f49;
  box-shadow: 0 0 0 0.25rem rgba(253, 49, 49, 0.5);
}

.form-check-input:focus {
  border-color: rgb(254, 134, 134);
  outline: 0px;
  box-shadow: rgba(253, 13, 13, 0.25) 0px 0px 0px 0.25rem;
}

.set_header {
  font-size: 15px;
  font-weight: 600;
}

/* media query */


@media only screen and (max-width:767px) {
  .nav-item.cust_flex .nav-link {
    height: 150px;
  }

  .nav-item.cust_flex {
    /* flex: 0.2 1; */
    width: 48%;
    float: left;
    margin: 1%;
  }
  .tab_img, .tab_img_active {
    width: 40px;
    margin: auto;
    /* filter: brightness(5); */
}
.nav-item.cust_flex {
  flex: auto!important;
}

}