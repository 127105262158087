.approve_reject_svg {
  width: 125px;
}

.colmn_height {
  height: 100vh;
  display: flex;
  align-items: center;
}

.svg_main_content {
  position: relative;
  padding: 30px 10px;
  box-shadow: 0px 0px 10px #dadada;
  width: 100%;
  /* background-image: url("../../../images//YAAMS_background_image.png"); */
  background-color: #fff;
  background-size: cover;
  background-position: center center;
}

.logo_bgimg {

  display: block;
  width: 250px;
  margin: auto;
  /* margin-bottom: 25px; */

}

.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.circle {
  -webkit-animation: dash 2s ease-in-out;
  animation: dash 2s ease-in-out;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.line {
  stroke-dashoffset: 1000;
  -webkit-animation: dash .9s .35s ease-in-out forwards;
  animation: dash .9s .35s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}

.check {
  stroke-dashoffset: -100;
  -webkit-animation: dash-check .9s .35s ease-in-out forwards;
  animation: dash-check .9s .35s ease-in-out forwards;
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}




@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}



@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }

  100% {
    stroke-dashoffset: 0;
  }
}



@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}



@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }

  100% {
    stroke-dashoffset: 900;
  }
}

/* .approve_reject_svg{
  width: 150px;
}
.colmn_height{
  height: 100vh;
  display: flex;
  align-items: center;
}
.svg_main_content {
  background: #fff;
  padding: 30px 10px;
  box-shadow: 0px 0px 10px #dadada;
  width: 100%;
}
.path {
  stroke-dasharray: 1000;
  stroke-dashoffset: 0;
}
.circle {
    -webkit-animation: dash 2s ease-in-out;
    animation: dash 2s ease-in-out;
    stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  }
.line {
    stroke-dashoffset: 1000;
    -webkit-animation: dash .9s .35s ease-in-out forwards;
    animation: dash .9s .35s ease-in-out forwards;
    stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  }
.check {
    stroke-dashoffset: -100;
    -webkit-animation: dash-check .9s .35s ease-in-out forwards;
    animation: dash-check .9s .35s ease-in-out forwards;
    stroke-dasharray: 1000;
  stroke-dashoffset: 0;
  }


 

@-webkit-keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

 

@keyframes dash {
  0% {
    stroke-dashoffset: 1000;
  }
  100% {
    stroke-dashoffset: 0;
  }
}

 

@-webkit-keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
}

 

@keyframes dash-check {
  0% {
    stroke-dashoffset: -100;
  }
  100% {
    stroke-dashoffset: 900;
  }
} */